<template>
  <b-sidebar
    id="hotel-details-sidebar"
    ref="sidebar"
    class="booking-sidebar"
    width="50%"
    title="Add Department"
    bg-variant="white"
    right
    shadow
    backdrop
    no-header
  >
    <template #default="{ hide }">
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5
          class="mb-0"
        >
          Hotel Booking Details
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>
      <b-card
        v-if="hotelBookingData && hotelBookingData.booking_details"
        no-body
        class="pb-1 box-shadow-0"
      >
        <b-tabs
          pills
          class="mt-1"
        >
          <b-tab
            title-link-class="ml-1"
            active
          >
            <template #title>
              <feather-icon
                icon="UserIcon"
                size="16"
                class="mr-0 mr-sm-50"
              />
              <span class="d-none d-sm-inline">Contact</span>
            </template>
            <!-- primary contact -->
            <PrimaryContact :contact-info="hotelBookingData.customer" />
          </b-tab>
          <b-tab>
            <template #title>
              <feather-icon
                icon="UsersIcon"
                size="16"
                class="mr-0 mr-sm-50"
              />
              <span class="d-none d-sm-inline">Guests</span>
            </template>
            <!--room-->
            <GuestInfo
              :guest-data="hotelBookingData.booking_details.rooms"
              :room-data="hotelBookingData.rooms"
            />
          </b-tab>
          <b-tab v-if="hotelBookingData.invoice">
            <template #title>
              <feather-icon
                icon="FileTextIcon"
                size="16"
                class="mr-0 mr-sm-50"
              />
              <span class="d-none d-sm-inline">Invoice</span>
            </template>
            <div class="pl-1">
              <invoice
                :invoice="hotelBookingData.invoice"
                :cross-sale="hotelBookingData.cross_sell_discount"
              />
            </div>
          </b-tab>
        </b-tabs>
      </b-card>
    </template>
  </b-sidebar>
</template>

<script>
import GuestInfo from './GuestInfo.vue'
import PrimaryContact from '@/components/common/PrimaryContact.vue'
import Invoice from '@/components/common/Invoice.vue'

export default {
  name: 'HotelBookingDetails',
  components: {
    PrimaryContact,
    GuestInfo,
    Invoice,
  },
  props: {
    hotelBookingData: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
