<template>
  <div class="px-2">
    <b-row class="mb-1">
      <b-col cols="4">
        <small class="text-muted">Name(s)</small>
      </b-col>
      <b-col cols="5">
        <small class="text-muted">Room</small>
      </b-col>
      <b-col cols="3">
        <small class="text-muted">Guest Info</small>
      </b-col>
    </b-row>
    <b-row
      v-for="(room, r) in guestData"
      :key="r"
      :class="r < guestData.length - 1 ? 'border-bottom mb-1' : ''"
    >
      <b-col cols="4">
        <p
          v-for="(pax, p) in room.paxes"
          :key="p+r"
          class="guest-name"
        >
          <span>{{ pax.name }} </span>
          <small class="text-muted">({{ formatPaxCode(pax.type) }})</small>
        </p>
      </b-col>
      <b-col cols="5">
        <p>{{ getRoomInfo(room.rate_key) }}</p>
      </b-col>
      <b-col cols="3">
        <p>{{ getTotalGuests(room.paxes) }}</p>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'GuestInfo',
  props: {
    guestData: {
      type: Array,
    },
    roomData: {
      type: Array,
    },
  },
  methods: {
    formatPaxCode(code) {
      switch (code) {
        case 'AD':
          return 'Adult'
        case 'CH':
          return 'Child'
        case 'INF':
          return 'Infant'
        default:
          return ''
      }
    },
    getRoomInfo(rateKey) {
      const rateKeyId = rateKey[0].board_id
      const roomInfo = this.roomData.filter(room => room.rates[0].rate_key[0].board_id === rateKeyId)[0]
      return `${roomInfo.name} (${roomInfo.rates[0].board_type.hotel_board_type_name})`
    },
    getTotalGuests(paxes) {
      let adult = 0
      let child = 0
      paxes.forEach(pax => {
        if (pax.type === 'AD') {
          adult += 1
        }
        if (pax.type === 'CH') {
          child += 1
        }
      })
      const adultString = adult > 1 ? `${adult} Adults` : `${adult} Adult`
      const childString = child ? `, ${child > 1 ? `${child} Children` : `${child} Child`}` : ''
      return `${adultString}${childString}`
    },
  },
}
</script>

<style lang="scss" scoped>
.guest-name {
  margin-bottom: 5px;
}
</style>
