<template>
  <div>
    <b-card no-body>
      <b-card-body>
        <b-row class="justify-content-end">
          <b-col
            cols="12"
            md="3"
            class="mb-md-0 mb-2"
          >
            <label for="start-date">From</label>
            <b-form-datepicker
              id="start-date"
              v-model="startDate"
              reset-button
              class="mb-2"
              :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
              @input="endDate=setEndDate(startDate, endDate)"
            />
          </b-col>
          <b-col
            cols="12"
            md="3"
            class="mb-md-0 mb-2"
          >
            <label for="end-date">To</label>
            <b-form-datepicker
              id="end-date"
              v-model="endDate"
              reset-button
              class="mb-2"
              :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
              @input="startDate=setStartDate(startDate, endDate)"
            />
          </b-col>
          <b-col
            cols="12"
            md="3"
            class="mb-md-0 mb-2"
          >
            <label for="checkin-date">Check-in</label>
            <b-form-datepicker
              id="checkin-date"
              v-model="checkInDate"
              reset-button
              class="mb-2"
              :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
              @input="checkOutDate=setEndDate(checkInDate, checkOutDate)"
            />
          </b-col>
          <b-col
            cols="12"
            md="3"
            class="mb-md-0 mb-2"
          >
            <label for="checkout-date">Check-out</label>
            <b-form-datepicker
              id="checkout-date"
              v-model="checkOutDate"
              reset-button
              class="mb-2"
              :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
              @input="checkInDate=setStartDate(checkInDate, checkOutDate)"
            />
          </b-col>
          <b-col
            cols="12"
            md="3"
            class="align-self-center"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                variant="primary"
                :disabled="isBtnLoading"
                @click="getRequestedQuery()"
              >
                <b-spinner
                  v-if="isBtnLoading"
                  small
                />
                Set Period
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <b-card no-body>
      <div class="m-2">
        <b-row class="justify-content-end">
          <!-- Search -->
          <b-col
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
                @input="debounceSearch(meta.offset=0, getRequestedQuery)"
              />
              <v-select
                v-model="statusQuery"
                :options="status"
                :reduce="option => option.value"
                :clearable="true"
                class="status-filter-select"
                placeholder="Select Status"
                @input="getHotelBookingList()"
              >
                <template #selected-option="{ label }">
                  <span class="text-truncate overflow-hidden">
                    {{ label }}
                  </span>
                </template>
              </v-select>
            </div>
          </b-col>
        </b-row>
      </div>
      <!--table-->
      <b-table
        responsive
        primary-key="id"
        show-empty
        empty-text="No matching records found"
        :items="hotelList"
        :fields="tableColumns"
        :busy="isTableLoading"
      >
        <template #table-busy>
          <div class="text-center text-primary m-2">
            <b-spinner class="align-middle" />
            <strong> Loading...</strong>
          </div>
        </template>
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <feather-icon
              :id="`${data.item.id}-preview-icon`"
              icon="EyeIcon"
              size="16"
              class="mx-1 cursor-pointer"
              @click="getBookingData(data.item)"
            />
            <b-dropdown
              v-if="data.item.status !== 'CANCELLED'"
              variant="link"
              toggle-class="p-0"
              class="action-menu"
              no-caret
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <template v-if="data.item.status === 'BOOKING_HOLD'">
                <b-dropdown-item
                  v-b-modal.hotel-modal
                  @click="getRequestedAction({ product_reference: data.item.booking_details.booking_reference }, 'Send Payment Link', 'send payment link for',
                                             'user_journey/offline_invoice/assisted_payment/',
                                             'post', false)"
                >
                  <feather-icon icon="SendIcon" />
                  <span class="align-middle ml-50">Send Payment Link</span>
                </b-dropdown-item>
              </template>
              <b-dropdown-item
                v-if="data.item.status === 'PENDING' || data.item.status === 'BOOKING_HOLD'"
                v-b-modal.hotel-modal
                @click="getRequestedAction({ invoice_id: data.item.invoice.invoice_id }, 'Confirm Booking', 'confirm', 'user_journey/booking_confirmation/', 'post', false)"
              >
                <feather-icon icon="CheckCircleIcon" />
                <span class="align-middle ml-50">Confirm Booking</span>
              </b-dropdown-item>
              <template v-if="data.item.status === 'SUCCESS'">
                <!-- <b-dropdown-item>
                  <feather-icon icon="MailIcon" />
                  <span class="align-middle ml-50">Send Mail</span>
                </b-dropdown-item> -->
                <b-dropdown-item
                  v-b-modal.hotel-modal
                  @click="getRequestedAction({}, 'Download Voucher', 'download voucher for', `/user_journey/invoice/${data.item.invoice.invoice_id}/hotel_voucher_download/`,'get', true)"
                >
                  <feather-icon icon="DownloadIcon" />
                  <span class="align-middle ml-50">Download Voucher</span>
                </b-dropdown-item>
              </template>
              <b-dropdown-item
                v-if="formatStatus(data.item.status) === 'Confirmed'"
                v-b-modal.hotel-modal
                @click="getRequestedAction({ booking_id: data.item.id, refund_amount: data.item.invoice.refund_amount }, 'Cancel Booking', 'cancel', 'user_journey/cancel_hotel_booking/', 'post', true)"
              >
                <feather-icon icon="XIcon" />
                <span class="align-middle ml-50">Cancel Booking</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </template>
        <template #cell(status)="data">
          <b-badge
            pill
            class="cursor-pointer"
            :variant="formatClassName(data.item.status)"
          >
            {{ formatStatus(data.item.status) }}
          </b-badge>
        </template>
      </b-table>
      <TablePagination
        :offset="meta.offset"
        :total="meta.total"
        :limit="meta.limit"
        :list-data="hotelList"
        @paginate="paginate"
      />
      <HotelBookingDetails :hotel-booking-data="bookingDetail" />
      <HotelActions
        :modal-data="hotelActionData"
        @getBookingList="getHotelBookingList"
      />
    </b-card>
  </div>
</template>

<script>
import api from '@/libs/api'
import helper from '@/mixins/helper'
import calendar from '@/mixins/calendar'
import TablePagination from '@/components/common/TablePagination.vue'
import HotelBookingDetails from './HotelBookingDetails.vue'
import HotelActions from '@/views/bookings/hotel/HotelActions.vue'

export default {
  name: 'HotelBookingList',
  components: {
    TablePagination,
    HotelBookingDetails,
    HotelActions,
  },
  mixins: [helper, calendar],
  data() {
    return {
      isTableLoading: false,
      isBtnLoading: false,
      hotelList: [],
      bookingDetail: {},
      searchQuery: '',
      startDate: '',
      endDate: '',
      checkInDate: '',
      checkOutDate: '',
      hotelActionData: {},
      statusQuery: '',
      status: [
        {
          label: 'Success',
          value: 'SUCCESS',
        },
        {
          label: 'Failed',
          value: 'FAILED',
        },
        {
          label: 'Cancelled',
          value: 'CANCELLED',
        },
        {
          label: 'Pending',
          value: 'PENDING',
        },
        {
          label: 'On Hold',
          value: 'BOOKING_HOLD',
        },
      ],
      meta: {
        offset: 0,
        limit: 10,
        total: 0,
      },
      tableColumns: [
        { key: 'actions', label: '' },
        { key: 'booking_details.booking_reference', label: 'Booking Id' },
        {
          key: 'created_at', label: 'Booking Time', thClass: 'lg', formatter: value => this.formatDate(value, 'DD MMM YYYY, hh:mm A'),
        },
        {
          key: 'status', label: 'Status', thClass: 'md',
        },
        {
          key: 'customer', label: 'Booked By', thClass: 'md', formatter: value => value.email,
        },
        {
          key: 'booking_details.hotel_name', label: 'Hotel Name', thClass: 'lg',
        },
        {
          key: 'booking_details.check_in', label: 'Check In', thClass: 'lg', formatter: value => this.formatDate(value, 'DD MMM YYYY'),
        },
        {
          key: 'booking_details.check_out', label: 'Check Out', thClass: 'lg', formatter: value => this.formatDate(value, 'DD MMM YYYY'),
        },
        {
          key: 'cancellation_date', label: 'Cancellation Date', thClass: 'lg', formatter: value => (value ? this.formatDate(value, 'DD MMM YYYY, hh:mm A') : 'N/A'),
        },
        {
          key: 'invoice.payable_amount', label: 'Payable Amount', thClass: 'md', formatter: value => `BDT ${Number(value).toLocaleString()}`,
        },
      ],
    }
  },
  computed: {
    filterQuery() {
      const startDate = this.startDate ? `&date_from=${this.startDate}` : ''
      const endDate = this.endDate ? `&date_to=${this.endDate}` : ''
      const checkInDate = this.checkInDate ? `&check_in=${this.checkInDate}` : ''
      const checkOutDate = this.checkOutDate ? `&check_out=${this.checkOutDate}` : ''
      const search = this.searchQuery ? `&search=${this.searchQuery}` : ''
      const status = this.statusQuery ? `&status=${this.statusQuery}` : ''
      return `${startDate}${endDate}${checkInDate}${checkOutDate}${search}${status}`
    },
  },
  mounted() {
    this.getHotelBookingList()
  },
  methods: {
    getHotelBookingList() {
      this.isTableLoading = true
      api.getData(`user_journey/go_biz/hotel_reservation_list/?offset=${this.meta.offset}${this.filterQuery}`, true).then(response => {
        if (response.data.status) {
          this.hotelList = response.data.result.results
          this.meta = response.data.result.meta
        } else {
          this.notificationMessage('danger', 'XIcon', 'Error', response.data.error.message)
        }
      }).catch().finally(() => {
        this.isTableLoading = false
        this.isBtnLoading = false
      })
    },
    paginate(offset) {
      this.meta.offset = offset
      this.getHotelBookingList()
    },
    getRequestedQuery() {
      this.isBtnLoading = true
      this.meta.offset = 0
      this.getHotelBookingList()
    },
    getBookingData(bookingData) {
      this.bookingDetail = bookingData
      this.$root.$emit('bv::toggle::collapse', 'hotel-details-sidebar')
    },
    getRequestedAction(data, title, text, apiEndpoint, apiMethod, isDownload) {
      this.hotelActionData = {
        data,
        title,
        text,
        apiEndpoint,
        apiMethod,
        isDownload,
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/style.scss';
</style>
